import React, { useEffect } from 'react';

const LinkedinAccount = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
 

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Making an Account on LinkedIn</h2>
      <p className="mb-4">Getting started on LinkedIn is easy. Here's how:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>Sign up using your professional email (make sure it sounds professional, e.g., your name or initials).</li>
        <li>Profile Picture: Use a clear, friendly headshot with a neutral background. No selfies or group photos!</li>
        <li>Headline: This is the first thing people see. Use something like: "Aspiring Front-End Developer | HTML, CSS, JavaScript | Seeking Internship Opportunities."</li>
      </ul>
      <p className="mb-4">Once you're set up, you're ready to dive in!</p>
      <h3 className="text-xl font-semibold mb-2">Create a LinkedIn profile</h3>
      <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src="https://www.youtube.com/embed/EWSuMW5IarU?si=fMqTMqdri4ern0Mk"
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
      <img className='mt-4' src="https://res.cloudinary.com/belong/image/upload/v1729578516/fullstack-bootcamp/what-next/Making_an_account_on_LinkedIn_zry1gw.png" alt="Making an account on LinkedIn" />
    </div>
  );
};

export default LinkedinAccount;