import React from 'react';

import SelfDiagnostic from './selfDiagnostic';
import TestAnalysis from './testAnalysis';

const Index = ({ topic, setTopic, enrollData, setReFetch }) => {
  
  return ( 
    <div>
      {/* Self Diagnostic Test Completed, then this will be shown */}
      {topic === 'self-diagnostic-test' && enrollData.self_diagnostic_test && (<div className='space-y-4'>
        <p className='text-center text-2xl font-bold'>Congratulations! You have completed the test.</p>
        <p className='text-center text-lg'>You can now access the 
          <span className='font-semibold text-primary'> Certificate & Way Forward </span> and see 
          <span className='font-semibold text-primary'> Test Analysis </span>.</p>
        </div>)}
      {/* Self Diagnostic Test Not Completed, then this will be shown */}
        {topic ==='self-diagnostic-test' && !enrollData.self_diagnostic_test && (
        <SelfDiagnostic
          enrollData={enrollData}
          setReFetch={setReFetch}
          setTopic={setTopic}
        />)}
        {/* Test Analysis will not be shown if Self Diagnostic Test is not completed */}
        {topic === 'test-analysis' && !enrollData.self_diagnostic_test && (
        <div className='space-y-4'>
          <p className='text-center text-2xl font-bold'>You have not completed the test yet.</p>
          <p className='text-center text-lg'>Please complete the test to access the 
            <span className='font-semibold text-primary'> Certificate & Way Forward </span> and see 
          <span className='font-semibold text-primary'> Test Analysis </span>.</p>
        </div>
      )}
      {/* Test Analysis will be shown only if Self Diagnostic Test is completed */}
      {topic === 'test-analysis' && enrollData.self_diagnostic_test && (
        <TestAnalysis enrollData={enrollData} setReFetch={setReFetch} />
      )}
    </div>
  );
};

export default Index;
