import React, { useState } from 'react';
import SelfDiagnosticInstructions from './SelfDiagnosticInstructions';
import DiagnosticTest from './diagnosticTest';
import {
  html_css_questions,
  bootstrap_js_questions,
  hosting_github_questions,
} from './questions';

const Index = ({setReFetch, setTopic}) => {
  const [testActive, setTestActive] = useState(false);

  const html_css_ques_info = {
    title: 'HTML & CSS',
    questions_data: html_css_questions,
    unique_id: 1,
  };

  const bootstrap_js_ques_info = {
    title: 'Bootstrap & JavaScript',
    questions_data: bootstrap_js_questions,
    unique_id: 2,
  };

  const hosting_github_ques_info = {
    title: 'Hosting & GitHub',
    questions_data: hosting_github_questions,
    unique_id: 3,
  };

  const [self_diagnostic_test, set_self_diagnostic_test] = useState({
    percentage: 0,
    correct_answer_count: 0,
    total_question_count: 0,
    response: {
      html_css_questions: html_css_ques_info.questions_data.map((ques) => {
        const temp = ques;
        temp.user_response_idx = -1;
        return temp;
      }),
      bootstrap_js_questions: bootstrap_js_ques_info.questions_data.map(
        (ques) => {
          const temp = ques;
          temp.user_response_idx = -1;
          return temp;
        }
      ),
      hosting_github_questions: hosting_github_ques_info.questions_data.map(
        (ques) => {
          const temp = ques;
          temp.user_response_idx = -1;
          return temp;
        }
      ),
    },
  });

  return (
    <div>
      {!testActive ? (
        <SelfDiagnosticInstructions
          setTestActive={setTestActive}
          isTestActive={testActive}
        />
      ) : (
        <DiagnosticTest
          self_diagnostic_test={self_diagnostic_test}
          set_self_diagnostic_test={set_self_diagnostic_test}
          setReFetch={setReFetch}
          setTopic={setTopic}
        />
      )}
    </div>
  );
};

export default Index;
