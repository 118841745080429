import React, { useEffect } from 'react';

const ColdEmailContact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Who to Write To and How to Find Contacts</h2>
            
            <h3 className="text-xl font-semibold mb-2">Who to Write To?</h3>
            <p className="mb-4">When sending cold emails, focus on reaching out to:</p>
            <ul className="list-disc pl-6 mb-4">
                <li><strong>Recruiters and HR Professionals:</strong> They are often the gatekeepers for internship opportunities.</li>
                <li><strong>Industry Professionals:</strong> Developers, tech leads, or people who inspire you. They may offer valuable advice or referrals.</li>
                <li><strong>Alumni or Bootcamp Graduates:</strong> People who've been in your position before may be more willing to help you out or provide guidance.</li>
            </ul>
            
            <h3 className="text-xl font-semibold mb-2">How to Find Contacts?</h3>
            <p className="mb-4">Finding the right contacts is key to successful outreach. Here's where to start:</p>
            <ul className="list-disc pl-6 mb-4">
                <li><strong>LinkedIn:</strong> Search for front-end developers, HR professionals, or recruiters at companies of interest. Look for titles like "Recruiter" or "Talent Acquisition."</li>
                <li><strong>Company Websites:</strong> Check the "Careers" or "Team" pages of companies for HR contact details or team members.</li>
                <li><strong>Email Tools:</strong> When contact details aren't listed, use these tools:
                    <ul className="list-circle pl-6 mt-2">
                        <li><strong>Prospeo:</strong> A tool that finds verified business emails, great for job seekers connecting with potential employers.</li>
                        <li><strong>Hunter.io:</strong> Find professional emails by searching domains or individuals, commonly used for outreach.</li>
                        <li><strong>RocketReach:</strong> A platform for finding professional contact details across industries, ideal for networking and business development.</li>
                    </ul>
                </li>
            </ul>
            
            <p className="mb-4 font-semibold">Your next opportunity could be one email away!</p>
            
            <h3 className="text-xl font-semibold mb-2">Resources:</h3>
            <div className="space-y-6">
                <div>
                    <p className='mb-2'>How to use Hunter.io to find professional emails</p>
            <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src="https://www.youtube.com/embed/Vy2DleYHryE?si=3O-_cEkH7rEBcjmV" 
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>
          </div>
                <p><a href="https://prospeo.io/email-finder" className="text-blue-600 hover:underline" rel="noreferrer noopener" target="_blank">Email Finder: Free email search for lead generation</a></p>
               <div>
               <p className='mb-2'>RocketReach Demo - How to get started with RocketReach</p>

               <iframe
          className='md:w-[560px] md:h-[315px] w-[100%]'
          src="https://www.youtube.com/embed/5V5t2iOJL3s?si=9tH8Uc0ZkIPMmPdA" 
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen></iframe>  
                </div>    
                </div>
        </div>
    );
};

export default ColdEmailContact;