import React, { useState, useEffect } from 'react';
import { LuClock3 } from 'react-icons/lu';

import SingleDiagnosticTest from './SingleDiagnosticTest';
import Section2DiagnosticTest from './Section2_DiagnosticTest';
import {
  html_css_questions,
  bootstrap_js_questions,
  hosting_github_questions,
} from './questions';
import { supabase } from '../../../../../../config/supabaseConfig';
import { useGetUserQuery } from '../../../../../profile/userSlice';

const DiagnosticTest = ({ self_diagnostic_test, set_self_diagnostic_test,setReFetch ,setTopic}) => {
  const { data: userData } = useGetUserQuery();
  const user_id = userData?._id;
  const section1_title = 'Section 1: Skill-based Questions';
  const section1_description =
    "For each multiple-choice question, you can select only one correct option. Let's get started!";

  const section2_title = 'Section 2: Reflective Activity';
  const section2_description =
    "This section is designed to help you understand your progress and guide you in identifying areas where you can grow further. For each question, reflect on your bootcamp experience and rate yourself on a scale of 1 to 5, where 1 means you're not confident, and 5 means you're very confident.";

  const html_css_ques_info = {
    title: 'HTML & CSS',
    questions_data: html_css_questions,
    unique_id: 1,
  };

  const bootstrap_js_ques_info = {
    title: 'Bootstrap & JavaScript',
    questions_data: bootstrap_js_questions,
    unique_id: 2,
  };

  const hosting_github_ques_info = {
    title: 'Hosting & GitHub',
    questions_data: hosting_github_questions,
    unique_id: 3,
  };

  // 30min as for now
  const [timeLeft, setTimeLeft] = useState(60 * 30);
  const [testSection, setTestSection] = useState(1);
  const [allAnswered, setAllAnswered] = useState(false);

  useEffect(() => {
    if (timeLeft === 0) {
      nextFunc();
      return;
    }

    const timerInterval = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(timerInterval);
    // eslint-disable-next-line
  }, [timeLeft]);

  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `00:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const checkIfAllAnswered = () => {
    // Check if all html_css answered
    for (const ques of self_diagnostic_test.response.html_css_questions) {
      if (ques.user_response_idx === -1) {
        return false;
      }
    }

    // Check if all bootstrap_js answered
    for (const ques of self_diagnostic_test.response.bootstrap_js_questions) {
      if (ques.user_response_idx === -1) {
        return false;
      }
    }

    // Check if all hosting_github answered
    for (const ques of self_diagnostic_test.response.hosting_github_questions) {
      if (ques.user_response_idx === -1) {
        return false;
      }
    }

    return true;
  };

  const nextFunc = () => {
    if (testSection === 1 && allAnswered) {
      setTestSection(2);
      //   setTimeLeft(60);
    }
  };
  
  

  const update_self_diagnostic_test_responses = (
    section,
    ques_idx,
    response_idx
  ) => {
    switch (section) {
      case 'html_css':
        set_self_diagnostic_test((prev) => ({
          ...prev,
          response: {
            ...prev.response,
            html_css_questions: prev.response.html_css_questions.map((q, idx) =>
              idx === ques_idx ? { ...q, user_response_idx: response_idx } : q
            ),
          },
        }));
        break;

      case 'bootstrap_js':
        set_self_diagnostic_test((prev) => ({
          ...prev,
          response: {
            ...prev.response,
            bootstrap_js_questions: prev.response.bootstrap_js_questions.map((q, idx) =>
              idx === ques_idx ? { ...q, user_response_idx: response_idx } : q
            ),
          },
        }));
        break;

      case 'hosting_github':
        set_self_diagnostic_test((prev) => ({
          ...prev,
          response: {
            ...prev.response,
            hosting_github_questions: prev.response.hosting_github_questions.map((q, idx) =>
              idx === ques_idx ? { ...q, user_response_idx: response_idx } : q
            ),
          },
        }));
        break;

      default:
        break;
    }

    setAllAnswered(checkIfAllAnswered());
  };

  const SubmitFunc = async() => {
    let correct_count = 0;
    let total_count = 0;
    
    // Individual category counts
    let html_css_correct = 0;
    let bootstrap_js_correct = 0;
    let hosting_github_correct = 0;

    // Check HTML & CSS questions
    self_diagnostic_test.response.html_css_questions.forEach((question) => {
      total_count++;
      if (
        question.user_response_idx === question.correct_answer_idx && 
        question.user_response_idx !== -1
      ) {
        correct_count++;
        html_css_correct++;
      }
    });

    // Check Bootstrap & JS questions
    self_diagnostic_test.response.bootstrap_js_questions.forEach((question) => {
      total_count++;
      if (
        question.user_response_idx === question.correct_answer_idx && 
        question.user_response_idx !== -1
      ) {
        correct_count++;
        bootstrap_js_correct++;
      }
    });

    // Check Hosting & GitHub questions
    self_diagnostic_test.response.hosting_github_questions.forEach((question) => {
      total_count++;
      if (
        question.user_response_idx === question.correct_answer_idx && 
        question.user_response_idx !== -1
      ) {
        correct_count++;
        hosting_github_correct++;
      }
    });

    const percentage = Math.round((correct_count / total_count) * 100 * 100) / 100;

    const updatedTest = {
      ...self_diagnostic_test,
      percentage,
      correct_answer_count: correct_count,
      total_question_count: total_count,
      category_scores: {
        html_css: html_css_correct,
        bootstrap_js: bootstrap_js_correct,
        hosting_github: hosting_github_correct
      },
      completed: true,
      completed_at: new Date().toISOString()
    };


    await supabase.from('full_stack_users').update({self_diagnostic_test: updatedTest}).match({user_id: user_id});
    setReFetch((pre) => !pre);
    setTopic('test-analysis');

  };

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-row justify-between items-center gap-4'>
        <div className='flex flex-col'>
          <span className='font-semibold'>
            {testSection === 1 && section1_title}
            {testSection === 2 && section2_title}
          </span>
          <span>
            {testSection === 1 && section1_description}
            {testSection === 2 && section2_description}
          </span>
        </div>
        <div className='rounded-3xl flex flex-row justify-between min-w-32 items-center border-2 border-gray-200 py-3 px-5 gap-2'>
          <LuClock3 />
          <span className='font-medium'>{formatTime(timeLeft)}</span>
        </div>
      </div>
      {testSection === 1 && (
        <React.Fragment>
          <SingleDiagnosticTest
            questions_data={html_css_ques_info.questions_data}
            title={html_css_ques_info.title}
            unique_id={html_css_ques_info.unique_id}
            update_self_diagnostic_test_responses={
              update_self_diagnostic_test_responses
            }
          />
          <SingleDiagnosticTest
            questions_data={bootstrap_js_ques_info.questions_data}
            title={bootstrap_js_ques_info.title}
            unique_id={bootstrap_js_ques_info.unique_id}
            update_self_diagnostic_test_responses={
              update_self_diagnostic_test_responses
            }
          />
          <SingleDiagnosticTest
            questions_data={hosting_github_ques_info.questions_data}
            title={hosting_github_ques_info.title}
            unique_id={hosting_github_ques_info.unique_id}
            update_self_diagnostic_test_responses={
              update_self_diagnostic_test_responses
            }
          />
        </React.Fragment>
      )}
      {testSection === 2 && (
        <React.Fragment>
          <Section2DiagnosticTest 
            self_diagnostic_test={self_diagnostic_test}
            set_self_diagnostic_test={set_self_diagnostic_test}
          />
        </React.Fragment>
      )}
      {testSection === 1 && (
        <button
          onClick={nextFunc}
          className={`py-2 px-5 rounded-lg text-white font-medium w-fit ${allAnswered ? 'bg-primary pointer-events-auto' : 'bg-stone-500 pointer-events-auto'}`}>
          {/* {testSection === 1 ? 'Continue to Section 2' : 'Continue'} */}
          Continue to section 2
        </button>
      )}
      {testSection === 2 && (
        <button
          onClick={SubmitFunc}
          disabled={self_diagnostic_test.response.reflective_activity.length !== 5}
          className={`py-2 px-5 rounded-lg text-white font-medium w-fit ${
            self_diagnostic_test.response.reflective_activity.length !== 5 
              ? 'bg-stone-500 opacity-50 cursor-not-allowed' 
              : 'bg-primary hover:bg-primary/90'
          }`}
          >
          Submit
        </button>
      )}
    </div>
  );
};

export default DiagnosticTest;
