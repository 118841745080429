import React, { useEffect } from 'react';

const ColdEmailNoResponse = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">What to Do If You Get No Response?</h2>
            
            <h3 className="text-xl font-semibold mb-2">Send a Follow-up Email:</h3>
            <p className="mb-4">If you don't hear back within 7-10 days, it's perfectly fine to send a polite follow-up.</p>
            <p className="mb-4">Here's a sample:</p>
            
            <div className="bg-gray-100 p-4 rounded-md mb-6">
                <p className="font-semibold">Subject: Following Up on My Internship Inquiry</p>
                <p className="mt-2">Hi [Name],</p>
                <p className="mt-2">I hope you're doing well. I wanted to follow up on my previous message regarding internship opportunities at [Company]. I'd love to discuss how my front-end development skills could contribute to the team.</p>
                <p className="mt-2">Looking forward to hearing from you.</p>
                <p className="mt-2">Best regards,<br/>[Your Name]</p>
            </div>
            
            <h3 className="text-xl font-semibold mb-2">When to Move On:</h3>
            <p className="mb-4">If you haven't received a response after 2-3 follow-ups, it's time to move on. Not every email will get a reply, and that's okay! Keep trying with new contacts, and eventually, you'll find the right opportunity - persistence is the key.</p>
            
            <h3 className="text-xl font-semibold mb-2">Final Tip:</h3>
            <p className="mb-4">Cold emailing can feel intimidating, but it's a powerful way to find internships and build connections. Keep your messages short, professional, and friendly, and always showcase your work. Be patient, follow up when needed, and stay positive. Good luck!</p>
            <img className='my-6 lg:w-[50%]' src="https://res.cloudinary.com/belong/image/upload/v1729578518/fullstack-bootcamp/what-next/What_to_do_if_you_get_no_response_zb4yeg.png" alt="cold-email" />

        </div>
    );
};

export default ColdEmailNoResponse;