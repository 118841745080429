import React from 'react';

const TestAnalysis = ({ enrollData }) => {
  const bootstrap_js_score = enrollData.self_diagnostic_test.category_scores.bootstrap_js;
  const html_css_score = enrollData.self_diagnostic_test.category_scores.html_css;
  const hosting_github_score = enrollData.self_diagnostic_test.category_scores.hosting_github;
const self_assessment_average = Object.values(enrollData.self_diagnostic_test.response.reflective_activity).reduce((sum, value) => sum + value, 0) / 6;

  // Helper function to determine category based on self assessment and test score
  const determineCategory = (selfScore, testScore) => {
    if (selfScore <= 3 && testScore >= 5) return 'under-confidence';
    if (selfScore <= 3 && testScore <= 4) return 'area-for-improvement';
    if (selfScore >= 4 && testScore >= 5) return 'strength';
    if (selfScore >= 4 && testScore <= 4) return 'over-confidence';
    return 'area-for-improvement'; // default case
  };

  // Analyze each skill area
  const skillAnalysis = {
    bootstrap_js: determineCategory(self_assessment_average, bootstrap_js_score),
    html_css: determineCategory(self_assessment_average, html_css_score),
    hosting_github: determineCategory(self_assessment_average, hosting_github_score)
  };

  // Feedback messages
  const feedbackMessages = {
    'under-confidence': {
      skills: 'You demonstrate strong practical knowledge despite lower self-assessment',
      feedback: 'You think you need more improvement, but your performance shows strong understanding of the material.',
      nextSteps: 'Continue practicing with more advanced tasks to build confidence in your knowledge and apply it in real-world projects.'
    },
    'area-for-improvement': {
      skills: 'Areas requiring additional practice and study',
      feedback: 'You think you need improvement, and your performance confirms there are knowledge gaps.',
      nextSteps: 'Focus on reviewing key concepts with tutorials and complete exercises to strengthen your foundation.'
    },
    'strength': {
      skills: 'Strong performance matching your self-assessment',
      feedback: 'You feel confident in your skills, and your performance confirms you have a strong grasp of the material.',
      nextSteps: 'Challenge yourself with advanced projects or mentor others to further deepen your knowledge and leadership skills.'
    },
    'over-confidence': {
      skills: 'Performance below self-assessed skill level',
      feedback: `You think you're highly skilled, but your performance suggests there are gaps in your understanding.`,
      nextSteps: 'Revisit the basics and focus on improving areas where your performance was weak, using targeted exercises.'
    }
  };

  // Get skills for each category
  const getSkillsForCategory = (category) => {
    return Object.entries(skillAnalysis)
      .filter(([_, cat]) => cat === category)
      .map(([skill]) => skill.replace('_', ' ').toUpperCase())
      .join(', ');
  };

  return (
    <div>
      {/* Analysis Section */}
      <div class="mb-6">
        <h1 class="text-2xl font-semibold text-gray-800">Analysis</h1>
        <p class="text-gray-600 mb-4">
          Based on your self-assessment and performance in the skill test, we've grouped your skills into four categories: 
          <strong> Under-Confidence</strong>, <strong>Area for Improvement</strong>, <strong>Strength</strong>, and <strong>Over-Confidence</strong>.
        </p>
        <p class="text-gray-600">
          These categories are designed to offer a balanced understanding of where your strengths lie and where there's potential for 
          further growth. Each section provides insights into how your self-perception aligns with your actual performance, along with 
          tailored feedback to help guide your development and next steps.
        </p>
      </div>

      {/* Grid Layout for Four Categories */}
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* <!-- Under-Confidence --> */}
        <div class="p-6 bg-[#F1FDFE] rounded-lg shadow-md">
          <div className='flex justify-between'>
            <div>
              <h2 class="text-lg font-semibold mb-2">Under-Confidence</h2>
              <p class="mb-4">
                You have more skill than you realize; focus on building confidence through advanced practice.
              </p>
            </div>
            <div>
              <img src="/images/logo1.png" className='object-contain w-20' alt="logo"></img>
            </div>
          </div>
          <div class="">
            <p><strong>Skills:</strong> {getSkillsForCategory('under-confidence')}</p>
            <p><strong>Feedback:</strong> {feedbackMessages['under-confidence'].feedback}</p>
            <p><strong>Next Steps:</strong> {feedbackMessages['under-confidence'].nextSteps}</p>
          </div>
        </div>
        
        {/* <!-- Area for Improvement --> */}
        <div class="p-6 bg-[#FFFBE6] rounded-lg shadow-md">
          <div className='flex justify-between'>
            <div>
              <h2 class="text-lg font-semibold mb-2">Area for Improvement</h2>
              <p class="mb-4">
                There are gaps in your understanding; review key concepts and practice more to strengthen your foundation.
              </p>
            </div>
            <div>
              <img src="/images/logo2.png" className='object-contain w-24' alt="logo"></img>
            </div>
          </div>
          <div class="">
            <p><strong>Skills:</strong> {getSkillsForCategory('area-for-improvement')}</p>
            <p><strong>Feedback:</strong> {feedbackMessages['area-for-improvement'].feedback}</p>
            <p><strong>Next Steps:</strong> {feedbackMessages['area-for-improvement'].nextSteps}</p>
          </div>
        </div>
        
        {/* <!-- Strength --> */}
        <div class="p-6 bg-[#F8FCE9] rounded-lg shadow-md">
          <div className='flex justify-between'>
            <div>
              <h2 class="text-lg font-semibold mb-2">Strength</h2>
              <p class="mb-4">
                You have a strong understanding in this area; challenge yourself with more complex projects to continue growing.
              </p>
            </div>
            <div>
              <img src="/images/logo3.png" className='object-contain w-24' alt="logo"></img>
            </div>
          </div>
          <div class="">
            <p><strong>Skills:</strong> {getSkillsForCategory('strength')}</p>
            <p><strong>Feedback:</strong> {feedbackMessages['strength'].feedback}</p>
            <p><strong>Next Steps:</strong> {feedbackMessages['strength'].nextSteps}</p>
          </div>
        </div>
        
        {/* <!-- Over-Confidence --> */}
        <div class="p-6 bg-[#FEF1F0] rounded-lg shadow-md">
          <div className='flex justify-between'>
            <div>
              <h2 class="text-lg font-semibold mb-2">Over-Confidence</h2>
              <p class="mb-4">
                Your self-perception is higher than your performance; revisit foundational concepts to address gaps in your knowledge.
              </p>
            </div>
            <div>
              <img src="/images/logo4.png" className='object-contain w-24' alt="logo"></img>
            </div>
          </div>
          <div class="">
            <p><strong>Skills:</strong> {getSkillsForCategory('over-confidence')}</p>
            <p><strong>Feedback:</strong> {feedbackMessages['over-confidence'].feedback}</p>
            <p><strong>Next Steps:</strong> {feedbackMessages['over-confidence'].nextSteps}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestAnalysis;