// Unique_id used to differentiate options of one section from other
const SingleDiagnosticTest = ({
  unique_id,
  title,
  questions_data,
  update_self_diagnostic_test_responses,
  self_diagnostic_test,
}) => {
  return (
    <div className='flex flex-col gap-3'>
      <span className='font-medium'>{title}</span>
      {questions_data.map((question, idx) => (
        <div
          key={idx}
          className='flex flex-col gap-5 p-4 border-2 border-gray-200 rounded-lg'>
          <div className='flex flex-row gap-2 font-medium'>
            <span>{idx + 1}. </span>
            <span>{question.question}</span>
          </div>
          <div className='flex flex-col px-5 gap-1'>
            {question.options.map((q_opt, opt_idx) => (
              <div key={opt_idx} className='flex flex-row gap-2 items-start'>
                <input
                  className='mt-[5px]'
                  type='radio'
                  name={`${question.question}`}
                  id={`${idx}_${opt_idx}_${unique_id}`}
                  onClick={() => {
                    switch (unique_id) {
                      case 1:
                        update_self_diagnostic_test_responses(
                          'html_css',
                          idx,
                          opt_idx
                        );
                        break;

                      case 2:
                        update_self_diagnostic_test_responses(
                          'bootstrap_js',
                          idx,
                          opt_idx
                        );
                        break;

                      case 3:
                        update_self_diagnostic_test_responses(
                          'hosting_github',
                          idx,
                          opt_idx
                        );
                        break;

                      default:
                        break;
                    }
                  }}
                />
                <label htmlFor={`${idx}_${opt_idx}_${unique_id}`} className=''>
                  {q_opt}
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SingleDiagnosticTest;
