import React, { useEffect } from 'react';

const PortfolioPublishing = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Publishing Your Work on Developer & Creative Platforms</h2>
            
            <p className="mb-4">Publishing your work on different platforms helps you reach a wider audience, allows others to engage with your projects, and demonstrates your skills in various contexts. As a beginner, sharing your projects on the right platforms can help you get noticed, gain feedback, and build your portfolio. Here are some excellent platforms to start with:</p>
            
            <h3 className="text-xl font-semibold mb-2">CodePen</h3>
            <p className="mb-4">CodePen is perfect for sharing live code snippets and small front-end projects. It's especially popular among front-end developers who want to experiment with code, collaborate, and get feedback from others.</p>
            <p className="mb-2">Resource:</p>
            <iframe
                className="w-full md:w-[560px] h-[315px] mb-4"
                src="https://www.youtube.com/embed/rYI8Nv6M1g8?si=t3PIFL-MmfGrIuZT"
                title="How to use CodePen"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>

            <h3 className="text-xl font-semibold mb-2">Stack Overflow</h3>
            <p className="mb-4">Stack Overflow is a go-to platform for asking and answering coding questions. By contributing solutions or code snippets, you can build your reputation as a problem solver and help others in the community.</p>
            <p className="mb-2">Resource:</p>
            <iframe
                className="w-full md:w-[560px] h-[315px] mb-4"
                src="https://www.youtube.com/embed/Vt-Wf7d0CFo?si=VGoWLV6AFSZqrytn" 
                title="How to Contribute to Stack Overflow"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>

            <h3 className="text-xl font-semibold mb-2">GitHub</h3>
            <p className="mb-4">GitHub is essential for version control and collaborating on open-source projects. Make sure to keep adding your work repositories here and organize them well. Employers often review GitHub repositories to evaluate a candidate's coding abilities. To make your repositories stand out:</p>
            <ul className="list-disc pl-6 mb-4">
                <li>Use descriptive README files.</li>
                <li>Showcase your best work at the top.</li>
                <li>Write clean, well-documented code.</li>
                <li>Organize your commits logically.</li>
            </ul>
            <p className="mb-2">Resource:</p>
            <iframe
                className="w-full md:w-[560px] h-[315px] mb-4"
                src="https://www.youtube.com/embed/rCt9DatF63I?si=xjMXyDiW7j3XNKnX"
                title="How to Create a Professional GitHub Profile"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>

            <h3 className="text-xl font-semibold mb-2">Hashnode</h3>
            <p className="mb-4">If you enjoy writing about your development journey, Hashnode is a fantastic platform to blog about your experiences. You can share tips, tutorials, or reflections on your projects and coding process, establishing yourself as a thought leader in the community.</p>
            <p className="mb-2">Resources:</p>
            <iframe
                className="w-full md:w-[560px] h-[315px] mb-4"
                src="https://www.youtube.com/embed/tOqhbbAAAlk?si=rpRMUWKZdp82ITnX" 
                title="Hashnode Blogging how to start"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
            <iframe
                className="w-full md:w-[560px] h-[315px] mb-4"
                src="https://www.youtube.com/embed/wEbsJZpz5Vo?si=6aPqQO45zNoGsTfW"
                title="How to Start Blogging with hashnode and without domain or Website"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>

            <p className="mt-4 font-semibold">Start sharing your work today, and let your skills speak for themselves!</p>
        </div>
    );
};

export default PortfolioPublishing;