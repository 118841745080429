import React, { useEffect } from 'react';

const LinkedinProfile = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Updating Your Profile</h2>
            <p className="mb-4">Think of your LinkedIn profile as your digital resume-it's often the first place professionals will check out before they even chat with you. Here's how to update your profile without repeating details across sections:</p>
            <ul className="list-disc pl-6 mb-4">
                <li>
                    <div>
                        <p><strong>Summary:</strong> The Summary should introduce you, your skills, and your career goals. Mention your portfolio in passing to provide a link to your work, but avoid giving too much project-specific information here.</p>
                        <p className="my-4">For Example :</p>
            <blockquote className="border-l-4 border-gray-500 pl-4 italic mb-4">
                "I'm a front-end developer passionate about creating responsive, user-friendly websites using HTML, CSS, Bootstrap, and JavaScript. I am constantly improving my skills by working on personal projects. You can check out my portfolio, which showcases my work and coding experience, here: [Link to website]."
            </blockquote>
            <p className="mb-4">This creates interest in your portfolio without duplicating the detailed project descriptions found in the <strong>Projects</strong> section.</p>

                </div>
                </li>
                <li><strong>Skills:</strong> Add all the technologies and tools you've learned and applied.(HTML, CSS, JavaScript, Git, etc.).</li>
                <li><strong>Experience:</strong> If you don't have formal work experience yet, this section is still valuable! Use it to highlight:
                    <ul className="list-disc pl-6 mt-2">
                        <li><strong>Internships:</strong> If you've had an internship, describe your responsibilities and what you learned.</li>
                        <li><strong>Freelance Projects:</strong> If you've worked on freelance projects, briefly mention the type of project and the skills you used.</li>
                        <li><strong>Volunteering:</strong> Volunteering for technical roles or web development projects can be showcased here.</li>
                        <li><strong>College Clubs & Societies:</strong> Highlight leadership roles, responsibilities, or activities in college tech clubs or societies. For example: "Tech Club President – Led a team to organize coding workshops and hackathons, boosting student engagement in programming and web development."</li>
                    </ul>
                </li>
            </ul>
            <p className="mb-4">This adds depth to your profile, showing that you've taken on responsibilities and used your skills outside the classroom.</p>
            <p className="mb-4"><strong>Tip:</strong> Ask for endorsements of your skills from peers or mentors, and request recommendations from anyone you've worked with on projects or in clubs.</p>
            
            <h3 className="text-xl font-semibold mb-2">Resources:</h3>
            <p className="mb-2">How to Create a LinkedIn Account</p>
            <iframe
                className='md:w-[560px] md:h-[315px] w-[100%]'
                src="https://www.youtube.com/embed/j2YA_TScR-E?si=Pp34r6OqK_S5JML1" 
                                title='How to Create a LinkedIn Account'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen></iframe>
                <p className='mt-4'>
                     <a href="https://www.linkedin.com/help/linkedin/answer/a540837?hcppcid=search" className="text-blue-600 hover:underline" rel="noreferrer noopener" target="_blank">How to add sections to profile</a>       
                </p>
        </div>
    );
};

export default LinkedinProfile;
