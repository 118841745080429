import React, { useEffect } from 'react';

const PortfolioBranding = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Personal Branding: Enhancing Your Brand with a Custom Domain</h2>
            
            <p className="mb-4">A custom domain is a simple but powerful way to enhance your personal brand. It adds a professional touch, makes your portfolio more memorable, and helps you stand out from other candidates.</p>
            
            <h3 className="text-xl font-semibold mb-2">Why Buy a Custom Domain?</h3>
            <p className="mb-4">A custom domain (e.g., "yourname.dev" or "yourname.com") signals that you take your work seriously. It shows potential employers that you are invested in your career, and it makes your portfolio easy to find and remember. Instead of sharing a long URL (like GitHub or a generic website builder link), you can direct people to a personalized, professional web address.</p>
            
            <h3 className="text-xl font-semibold mb-2">How to Buy a Domain</h3>
            <p className="mb-4">Buying a domain is simple, and it's a worthwhile investment in your personal brand. Here's how you can get started:</p>
            <ol className="list-decimal pl-6 mb-4">
                <li><strong>Choose a Domain Name:</strong> Select a domain that reflects your personal brand, ideally something simple and professional like your name or your brand (e.g., "johndoe.dev" or "johndoeportfolio.com").</li>
                <li><strong>Register the Domain:</strong> You can purchase your domain through trusted platforms like GoDaddy, Namecheap, or Google Domains. These services make it easy to search for available domains and complete the purchase in a few clicks.</li>
            </ol>
            <p className="mb-2">Resource:</p>
            <iframe
                className="w-full md:w-[560px] h-[315px] mb-4"
                src="https://www.youtube.com/embed/T6mKmpcyMAc?si=C00hP1GP2_INry3i"
                title="How to Buy a Domain Name on GoDaddy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>

            <h3 className="text-xl font-semibold mb-2">Building Credibility with a Custom Domain</h3>
            <p className="mb-4">A custom domain sets you apart from other candidates by showing that you're serious about your work. It also:</p>
            <ul className="list-disc pl-6 mb-4">
                <li><strong>Adds Professionalism:</strong> A custom domain makes your portfolio look polished and professional, giving a positive first impression.</li>
                <li><strong>Builds Trust:</strong> When employers or collaborators visit your custom domain, it creates a sense of trust and credibility. It shows that you are organized, detail-oriented, and dedicated to your craft.</li>
                <li><strong>Enhances Visibility:</strong> A memorable, easy-to-share URL improves your online presence and makes it simpler for potential employers, collaborators, and mentors to find your work.</li>
            </ul>

            <p className="mt-4 font-semibold">By creating a professional online presence with a custom domain, you'll stand out in a competitive job market and build a brand that reflects the quality of your work.</p>
            <img className='my-6 lg:w-[50%]' src="https://res.cloudinary.com/belong/image/upload/v1729578515/fullstack-bootcamp/what-next/custom_domain_name_ik486e.jpg" alt="cold-email" />          

        </div>
    );
};

export default PortfolioBranding;