import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Loading from '../components/layout/Loading';
import { useGetUserQuery } from '../components/profile/userSlice';
import { partnerId } from '../config/PartnerIds';
import { API_URL } from '../config/API_LINKS';
import { axiosApi } from '../config/Axios';

const PaidUsers = () => {
  const { data: userData, isLoading } = useGetUserQuery();
  const [paymentDone, setPaymentDone] = useState(false);
  const [loading, setLoading] = useState(true);

  const userEmail = userData?.email;

  const isPaidUser =
    userData?.roles.includes('Admin') ||
    userData?.roles.includes('Team') ||
    userEmail === 'test@ingeniousfaces.com' ||
    userData?.partnerId?._id === partnerId.innovationMissionPunjab ||
    paymentDone;

  useEffect(() => {
    const setDataFn = async () => {
      const orderData = await axiosApi.get(API_URL + '/order', {
        params: {
          email: userEmail,
          orderType: 'FSD',
        },
      });
      //   console.log(orderData);
      if (
        orderData?.data?.data &&
        orderData?.data?.data[0]?.status === 'Paid'
      ) {
        setPaymentDone(true);
      }
      setLoading(false);
    };
    if (userEmail) {
      setDataFn();
    }
  }, [userEmail]);

  return isLoading || loading ? (
    <Loading />
  ) : isPaidUser ? (
    <Outlet />
  ) : (
    <div className='text-2xl text-center font-semibold mt-20'>
      You are not authorized to access this dashboard
    </div>
  );
};

export default PaidUsers;
