import React, { useEffect } from 'react';

const WhatCanYouDo = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">What Can You Do?</h2>
            
            <h3 className="text-xl font-semibold mb-2">Blogging</h3>
            <p className="mb-4">Blogging is a fantastic way to reflect on your learning, showcase your projects, and share insights with others. Writing about your experiences not only helps you grow as a developer but also establishes you as a knowledgeable voice in the tech community. You can write about:</p>
            <ul className="list-disc pl-6 mb-4">
                <li>Challenges you faced during the bootcamp.</li>
                <li>Tips for mastering HTML, CSS, JavaScript, or Git.</li>
                <li>How you solved specific coding problems.</li>
            </ul>
            <p className="mb-4">Platforms like <a href="https://medium.com/" className='font-semibold underline text-primary' target="_blank" rel="noopener noreferrer">Medium</a> and <a href="https://dev.to/" className='font-semibold underline text-primary' target="_blank" rel="noopener noreferrer">Dev.to </a> are ideal for blogging about development-related topics. These platforms help you explain your thought process behind projects, share technical insights, and establish yourself as a thought leader in the developer community. Blogging can also catch the attention of recruiters, adding value to your portfolio.</p>
            
            <h4 className="text-lg font-semibold mb-2">Resources:</h4>
            <ul className="list-disc pl-6 mb-4">
            <p className="mb-2">How to Start Writing on Medium (2024) Medium Article Writer Tutorial</p>

                <iframe
                className="w-full md:w-[560px] h-[315px] mb-4"
                src="https://www.youtube.com/embed/rLIWnhrXLxY?si=gFCSrgC5_EU3wcTV" 
                title="Forget employability, it is all about mindset"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
                <li><a href="https://dev.to/devteam/writing-your-first-post-on-dev-3m13" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">Writing your first post on DEV ✍️</a></li>
                <li><a href="https://dev.to/rizalion/my-first-web-development-project-learning-html-and-css-2a9k" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">Sample of a blog post: My First Web Development Project: Learning HTML and CSS - DEV Community</a></li>
            </ul>

            <h3 className="text-xl font-semibold mb-2">New Projects</h3>
            <p className="mb-4">If you feel like you're not ready to take on projects by yourself, don't worry! You can start with Capstone Projects from Belong. These guided industry projects allow you to build your skills by solving real-world challenges from companies. You'll also generate proof of work and earn a company-branded certificate to add to your portfolio.</p>
            <p className="mb-4">Capstone Projects are ideal if you're looking for a structured and guided experience where you'll receive support while solving real-world challenges. To get started with Capstone Projects, email <a href="mailto:connect@belong.education" className='text-primary font-semibold underline'>connect@belong.education</a> for more information.</p>
            <p className="mb-4">When you feel confident enough to explore independent projects, you can:</p>
            <ul className="list-disc pl-6 mb-4">
                <li><strong>Work on personal passion projects</strong>: Build something you've always wanted to create, like a personal app or a unique website. Passion projects help you stay motivated and show creativity in your portfolio.</li>
                <li><strong>Contribute to open-source projects on platforms like GitHub</strong> : Look for projects with open issues or join ongoing initiatives to build and improve your coding skills while collaborating with other developers.</li>
                <li><strong>Participate in hackathons or coding challenges</strong>: Hackathons are a great way to collaborate with others and work under pressure. Coding challenges help sharpen your problem-solving abilities and boost your technical confidence.</li>
            </ul>
            <p className="mb-4">For more independent practice, you can explore project ideas on these platforms:</p>
            <ul className="list-disc pl-6 mb-4">
                <li><a href="https://github.com/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">GitHub</a>: Browse open-source projects, contribute to existing projects, or find inspiration for your own personal work.</li>
                <li><a href="https://devchallenges.io/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">DevChallenges</a>: Take on real-world front-end challenges to build your skills independently.</li>
                <li><a href="https://www.frontendmentor.io/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">Frontend Mentor</a>: Work on front-end project briefs that simulate real client work.</li>
                <li><a href="https://codewars.com" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">Codewars</a>: Practice coding challenges to improve your problem-solving and algorithmic skills.</li>
            </ul>
            <p className="mb-4">These resources will keep your portfolio fresh and showcase your latest skills. However, if you're looking for a more structured experience with real-world challenges and feedback, Belong Capstone Projects are the perfect place to start.</p>
            
            <h4 className="text-lg font-semibold mb-2">Resources:</h4>
            <ul className="list-disc pl-6 mb-4">
                <li><a href="https://www.youtube.com/shorts/QKQDFSVB0Z0"className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">How to Contribute to Open Source projects as a Beginner| GSOC | Git Hub</a></li>
                <li><a href="https://www.youtube.com/shorts/cugkROzKAa4" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">How to Contribute to Open Source? Here's Everything You Need to Know - YouTube</a></li>
                <li><a href="https://www.freecodecamp.org/news/how-to-contribute-to-open-source-handbook/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">How to Contribute to Open-Source Projects – A Handbook for Beginners</a></li>
            </ul>

            <h3 className="text-xl font-semibold mb-2">Join Developer Communities</h3>
            <p className="mb-4">Becoming part of a developer community helps you stay updated with industry trends, meet fellow developers, and gain inspiration for your next project. Here are some great communities to join:</p>
            <ul className="list-disc pl-6 mb-4">
                <li><a href="https://stackoverflow.com/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">Stack Overflow</a>: Ask questions, find solutions, and contribute to the global developer community.</li>
                <li><a href="https://github.com/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">GitHub</a>: Share your projects, collaborate with others, and contribute to open-source projects.</li>
                <li><a href="https://dev.to/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">Dev.to</a>: A community for sharing blogs and technical insights. Perfect for reading articles or posting your own.</li>
                <li><a href="https://hashnode.com/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">Hashnode</a>: Start your own blog or read articles by other developers.</li>
                <li><a href="https://codepen.io/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">CodePen</a>: Showcase front-end code snippets and get feedback from other developers.</li>
                <li><a href="https://www.reddit.com/r/webdev/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">Reddit - WebDev</a>: Join discussions, ask questions, and share insights with a broad web development community.</li>
            </ul>

            <h3 className="text-xl font-semibold mb-2">Ask for Feedback</h3>
            <p className="mb-4">Don't hesitate to ask for feedback from industry professionals or your peers. Getting feedback helps you identify areas of improvement and refine your coding skills. You can:</p>
            <ul className="list-disc pl-6 mb-4">
                <li>Share your website or GitHub repo with developers you respect.</li>
                <li>Reach out to mentors or instructors for their advice.</li>
            </ul>
            <p className="mb-4">Here are a few message templates for LinkedIn outreach to request feedback. Please improve on these to create a well-crafted message for yourself.</p>
            
            <h4 className="text-lg font-semibold mb-2">Template 1: Requesting Feedback on a Project</h4>
            <p className="mb-4">Subject: Request for Feedback on My Front-End Project</p>
            <p className="mb-4">Hi [Name], I hope you're doing well. My name is [Your Name], and I'm a front-end developer who just completed a Full Stack Bootcamp. I've built a personal portfolio website as part of the program, and I would greatly appreciate your feedback on it. Here's the link to my website: [Website Link]. I'd love to hear your thoughts on how I could improve it further. Thank you in advance! Best regards, [Your Name].</p>
            
            <h4 className="text-lg font-semibold mb-2">Template 2: Seeking Guidance from a Senior Developer</h4>
            <p className="mb-4">Subject: Looking for Advice on Front-End Development</p>
            <p className="mb-4">Hi [Name], I came across your profile and was inspired by your work in front-end development. I recently finished a Full Stack Bootcamp, and I've built a portfolio website showcasing my projects. I'd be grateful if you could take a look and share any advice on how I could improve my skills or make my portfolio more appealing to employers. Here's the link: [Website Link]. Thank you for your time and insights! Best, [Your Name].</p>
            
            <h4 className="text-lg font-semibold mb-2">Template 3: Requesting Feedback from a Peer or Bootcamp Alumni</h4>
            <p className="mb-4">Subject: Feedback Request from a Fellow Developer</p>
            <p className="mb-4">Hi [Name], I noticed that you've also gone through a Full Stack Bootcamp, and I thought it'd be great to connect! I've just completed my bootcamp and built a portfolio website, and I'm looking for feedback from fellow developers to help me improve. Would you mind taking a quick look? Here's the link: [Website Link]. I'd really appreciate any advice you can share. Thanks so much! Best regards, [Your Name].</p>
            
            <p className="mb-4">Learning from others is one of the fastest ways to grow, so stay open to constructive feedback.</p>
        </div>
    );
};

export default WhatCanYouDo;