import React, { useEffect } from 'react';

const ActionableQuestion = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Actionable Questions for Reflection</h2>
                        
            <ul className="list-disc pl-6 mb-4">
                <li>What did I learn from the bootcamp that I can't wait to apply in my next project?</li>
                <li>What are the biggest improvements I've seen in my work since starting the bootcamp?</li>
                <li>How can I keep building on the skills I've gained?</li>
                <li>What's the next technology or tool I want to explore to stay ahead in front-end development?</li>
            </ul>

            
        </div>
    );
};

export default ActionableQuestion;