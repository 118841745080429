import { FaCheck } from 'react-icons/fa';
import React, { useState } from 'react';
// import SubHeadingComponent from './SubHeadingComponent';
// import WayForward from './WayForward';

const WhatNextRow = ({
  idx,
  onClick,
  isChecked,
  isSelected,
  title,
  prevChecked,
  setSelectedSubHeading,
}) => {
  const [expandedHeadings, setExpandedHeadings] = useState({});

  const toggleHeading = (heading) => {
    setExpandedHeadings(prev => ({ ...prev, [heading]: !prev[heading] }));
  };

  const handleSubHeadingClick = (heading, subHeading) => {
    setSelectedSubHeading(`${heading}-${subHeading}`);
  };

  const headings = {
    '3.1 Building Your Presence On LinkedIn': [
        'Making an Account on LinkedIn', 
        'Updating Your Profile', 
        'Adding Your Project on LinkedIn',
        'How to Network?',
        'How to Strike Conversations on LinkedIn?',
        'Post, Share and Reply on LinkedIn'
    ],
    '3.2 Mastering Cold Email Outreach': [
      'What and Why of Cold Emailing',
      'Structure of Cold Emails',
      'Sample Cold Emails for Outreach to HRs and Industry Contacts',
      'Who to Write To and How to Find Contacts',
      'What to Do If You Get No Response?'
    ],
    '3.3 Expanding Portfolio Visibility': [
      'Why Expand Your Portfolio Visibility?',
      'Publishing Your Work on Developer & Creative Platforms',
      'Personal Branding: Enhancing Your Brand with a Custom Domain'
    ],
    '3.4 Continuous Growth & Improvement': [
      'What Should Be Your Mindset?',
      'What Can You Do?',
      'Actionable Questions for Reflection'
    ],
  };

  return (
    <div className='flex flex-col'>
      <div
        onClick={onClick}
        className={`flex flex-row justify-between items-center cursor-pointer gap-5 px-5 py-4 ${
          isSelected ? 'bg-cyan-50' : 'bg-white'
        } ${!prevChecked ? 'pointer-events-none opacity-50' : 'pointer-events-auto opacity-100'}`}>
        <span className={`${isSelected ? 'font-semibold' : ''}`}>
          {idx + 1}. {title}
        </span>
        <div className='h-fit w-fit'>
          {isChecked && <FaCheck className='bg-primary text-white text-xl p-[3px] rounded-3xl' />}
        </div>
      </div>
      {isSelected && (
        <div className='flex flex-col pl-2 max-h-[30vh] overflow-y-auto custom-scrollbar'>
          {Object.entries(headings).map(([heading, subHeadings]) => (
            <div key={heading} className='mb-2'>
              <div
                className='cursor-pointer'
                onClick={() => toggleHeading(heading)}
              >
                {expandedHeadings[heading] ? (
                  <span className='text-primary'>{heading}</span>
                ) : (
                  <span className='text-black'>{heading}</span>
                )}
              </div>
              {expandedHeadings[heading] && (
                <ul className='pl-5 mt-2 space-y-2 list-disc'>
                  {subHeadings.map((subHeading) => (
                    <li
                      key={`${heading}-${subHeading}`}
                      className='cursor-pointer hover:text-blue-600 text-sm'
                      onClick={() => handleSubHeadingClick(heading, subHeading)}
                    >
                      {subHeading}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      )}
     
      <style jsx>{`
        .custom-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: #4fd1c5 #e2e8f0;
        }

        .custom-scrollbar::-webkit-scrollbar {
          width: 8px;
        }

        .custom-scrollbar::-webkit-scrollbar-track {
          background: #e2e8f0;
          border-radius: 4px;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: #4fd1c5;
          border-radius: 4px;
          border: 2px solid #e2e8f0;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background-color: #38b2ac;
        }
      `}</style>
    </div>
  );
};

export default WhatNextRow;
