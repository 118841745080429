import { reflective_activity_questions } from './questions';

const Section2DiagnosticTest = ({ self_diagnostic_test, set_self_diagnostic_test }) => {
  const handleOptionSelect = (questionIdx, value) => {
    set_self_diagnostic_test(prev => ({
      ...prev,
      response: {
        ...prev.response,
        reflective_activity: {
          ...prev.response.reflective_activity,
          [questionIdx]: value
        }
      }
    }));
  };

  const options = [
    'Not Confident',
    '',
    'Somewhat Confident',
    '',
    'Very Confident',
  ];

  const unique_id = 3;
  return (
    <div className='flex flex-col gap-3'>
      {reflective_activity_questions.map((question, idx) => (
        <div className='flex flex-col gap-5 p-4 border-2 border-gray-200 rounded-lg'>
          <div className='flex flex-row gap-2 font-medium'>
            <span>{idx + 1}. </span>
            <span>{question.question}</span>
          </div>
          <div className='flex flex-row justify-evenly'>
            {options.map((opt, opt_idx) => (
              <div
                key={opt_idx}
                className='flex flex-col gap-2 items-center w-max'>
                <input
                  className='mt-[5px]'
                  type='radio'
                  name={`${idx}`}
                  id={`${idx}_${opt_idx}_${unique_id}`}
                  onChange={() => handleOptionSelect(idx, opt_idx + 1)}
                  checked={self_diagnostic_test.response.reflective_activity?.[idx] === opt_idx + 1}
                />
                <label
                  htmlFor={`${idx}_${opt_idx}_${unique_id}`}
                  className='font-medium'>
                  {opt_idx + 1}
                </label>
                <label
                  htmlFor={`${idx}_${opt_idx}_${unique_id}`}
                  className='text-xs'>
                  {opt}
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Section2DiagnosticTest;
