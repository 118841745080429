import { useEffect } from 'react';
import './App.css';
import Routes from './routes';
import { decodeJwt, importJWK, jwtVerify } from 'jose';
import Cookies from 'js-cookie';

function App() {
  const jwksUri = `https://cognito-idp.us-east-2.amazonaws.com/us-east-2_h5OexVjb0/.well-known/jwks.json`;

  // Fetch JWKS (Public Key Set)

  // Validate the JWT

  useEffect(() => {
    const fetchJWKS = async (kid) => {
      try {
        const response = await fetch(jwksUri);
        if (!response.ok) throw new Error('Failed to fetch JWKS');
        const { keys } = await response.json();

        // Find the key with matching `kid`
        const key = keys.find((k) => k.kid === kid);
        if (!key) throw new Error(`Key not found for kid: ${kid}`);

        // Convert JWKS to a usable key format
        return await importJWK(key, 'RS256');
      } catch (error) {
        console.error('Error fetching JWKS:', error);
        throw error;
      }
    };
    const validateToken = async (token) => {
      try {
        // Decode the JWT to extract the header and payload
        const decoded = decodeJwt(token);
        if (!decoded || typeof decoded === 'string' || !decoded.kid) {
          throw new Error('Invalid token');
        }

        // Fetch the public key for the `kid`
        const publicKey = await fetchJWKS(decoded.kid);

        // Verify the token's signature
        const { payload } = await jwtVerify(token, publicKey, {
          issuer: `https://cognito-idp.us-east-2.amazonaws.com/us-east-2_h5OexVjb0`,
          audience: '20738icdfn538kieuetqrajoj2',
        });

        console.log('Token is valid:', payload);
        return payload; // Return the decoded payload
      } catch (error) {
        console.error('Token validation failed:', error);
        throw error;
      }
    };
    const authenticateUser = async () => {
      const token = Cookies.get('authToken'); // Get token from cookie
      if (!token) {
        console.error('No token found. Redirecting to login.');
        // window.location.href = 'https://dashboard.belong.education/login'; // Redirect to login
        return;
      }

      try {
        const user = await validateToken(token);
        console.log('Authenticated user:', user);
        // Proceed with authenticated user logic here
      } catch (error) {
        console.error('Authentication failed:', error);
        // window.location.href = 'https://dashboard.belong.education/login'; // Redirect to re-login
      }
    };

    authenticateUser(); // Validate token on app load
  }, [jwksUri]);

  return (
    <div className='h-screen'>
      <Routes />
    </div>
  );
}

export default App;
