import React, { useEffect } from 'react';

const ColdEmailWhat = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">What and Why of Cold Emailing</h2>
            
            <h3 className="text-xl font-semibold mb-2">What is a Cold Email?</h3>
            <p className="mb-4">A cold email is a professional message sent to someone you don't know to introduce yourself, highlight your skills, and explore potential opportunities. For beginner front-end developers, cold emails are a great way to connect with industry professionals, recruiters, or potential mentors who may not be familiar with you yet.</p>
            
            <h3 className="text-xl font-semibold mb-2">What is the Purpose of a Cold Email Reachout?</h3>
            <p className="mb-4">The goal of a cold email is simple: introduce yourself, showcase your skills, and express interest in an opportunity. You're not just asking for a job; you're starting a conversation that can lead to connections, advice, or even a potential internship.</p>
            
            <p className="mb-4">As a front-end developer just starting out, cold emailing is an essential networking tool. It allows you to:</p>
            <ul className="list-disc pl-6 mb-4">
                <li><strong>Reach out to professionals</strong>  who might not know you yet.</li>
                <li><strong>Showcase your work and potential</strong> to people who could open doors for you.</li>
                <li><strong>Build relationships</strong> that can lead to advice, guidance, or future opportunities.</li>
            </ul>
            
            <p className="mb-4">Cold emailing is all about approach and personalization. Start by understanding the right structure, craft your message carefully, and keep it short, professional, and focused on building genuine connections rather than just asking for favors.</p>
        <img className='my-6 lg:w-[60%]' src="https://res.cloudinary.com/belong/image/upload/v1729578515/fullstack-bootcamp/what-next/cold_email_obwsxu.png" alt="what-cold-email" />
       </div>
    );
};

export default ColdEmailWhat;