import React, { useEffect } from 'react';

const PortfolioVisibility = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Why Expand Your Portfolio Visibility?</h2>
            
            <p className="mb-4">The more people who see your work, the more opportunities you'll create for yourself. Expanding your portfolio's visibility is essential for:</p>
            
            <ul className="list-disc pl-6 mb-4">
                <li><strong>Attracting Potential Employers:</strong> Recruiters and hiring managers are constantly searching for fresh talent. A well-organized and visible portfolio helps them discover your skills and what you bring to the table.</li>
                <li><strong>Building Credibility:</strong> Sharing your work across multiple platforms shows that you take your craft seriously. It establishes you as a professional developer and someone worth considering for projects and internships.</li>
                <li><strong>Networking Opportunities:</strong> Developers, mentors, or other professionals might come across your portfolio and reach out for collaborations, mentorship, or even job offers.</li>
            </ul>
            <img className='my-6 lg:w-[50%]' src="https://res.cloudinary.com/belong/image/upload/v1729578516/fullstack-bootcamp/what-next/Portfolio-visibility_g3fl8m.png" alt="cold-email" />          
        </div>
    );
};

export default PortfolioVisibility;