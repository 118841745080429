import React, { useEffect } from 'react';

const LinkedinPost = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Post, Share and Reply on LinkedIn</h2>
            <p className="mb-4">To stay visible and relevant on LinkedIn, it's important to engage actively with the community. Here's how:</p>
            <ul className="list-disc pl-6 mb-4 space-y-6">
                <li>
                    <p><strong>Post Regularly:</strong> Share updates about your development journey, projects, or learning experiences. Regular posts showcase your growth and keep your network informed about what you're working on.</p>
                    <p className="mb-4">For example, you could post about completing a bootcamp or building a new project:</p>
                    <blockquote className="border-l-4 border-gray-500 pl-4 italic mb-4">"Just completed a 21-day Front-End Bootcamp! Built my first personal website using HTML, CSS, and JavaScript. Excited to continue learning and connecting with fellow developers!"</blockquote>
                </li>
                <li>
                    <p><strong>Share Insights and Industry Trends:</strong> Stay on top of industry trends or share your take on new technologies. When you come across interesting articles, updates, or tools, share them with your network and add your perspective. This helps you position yourself as an engaged and knowledgeable professional.</p>
                </li>
                <li>
                    <p><strong>Ask for Constructive Feedback:</strong> If you're working on a project, don't hesitate to ask for feedback from your connections.</p>
                    <p className="mb-4">For example, after completing your portfolio, post a link and invite feedback from others in your network.</p>
                    <blockquote className="border-l-4 border-gray-500 pl-4 italic mb-4">"I've just finished my portfolio website. I'd love to get any constructive feedback on how I can improve it. Here's the link: [Link to website]."</blockquote>
                </li>
                <li>
                    <p><strong>Engage with Industry Posts:</strong> When industry leaders or professionals share insights or updates, don't just like the post-engage with it. Comment with thoughtful responses, ask questions, or offer your opinion. This helps you stay visible and connect with others in your field.</p>
                </li>
                <li>
                    <p><strong>Follow Up with Replies:</strong> If people comment on your posts, don't just leave it at that. Reply to their comments and continue the conversation. This shows you're engaged and values input from your network.</p>
                </li>
            </ul>
            <img className='my-6 lg:w-[60%]' src="https://res.cloudinary.com/belong/image/upload/v1729578516/fullstack-bootcamp/what-next/Post_share_and_reply_kgwadh.png" alt="Post" />
            <h3 className="text-xl font-semibold mb-2">Resources:</h3>
            <div >
            <p className="my-2">How to share a LinkedIn post</p>

                <iframe
                className='md:w-[560px] md:h-[315px] w-[100%]'
                src="https://www.youtube.com/embed/vW4iMkTZNvY?si=D24Kj-87m588UjSU"         
                                      title='How to Create a LinkedIn Account'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen>

                </iframe>
                <p className="mt-6 mb-2">How to share a LinkedIn post</p>

                <iframe
                className='md:w-[560px] md:h-[315px] w-[100%]'
                src="https://www.youtube.com/embed/F_A1EmQvH1k?si=TLAmuSWFII8WyOQj"       
                                      title='How to Create a LinkedIn Account'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen>

                </iframe>
                </div>
               
        
        </div>
    );
};

export default LinkedinPost;