import React, { useEffect } from 'react';

const LinkedinConversation = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">How to Strike Conversations on LinkedIn?</h2>
            <p className="mb-4">After connecting with people, start meaningful conversations:</p>
            <h3 className="text-xl font-semibold mb-2">Comment on Posts:</h3>
            <p className="mb-4">When someone shares an article or update, leave a thoughtful comment that adds value to the discussion. Share your insights, ask questions, or offer a new perspective.</p>
            <p className="mb-2">For example:</p>
            <blockquote className="border-l-4 border-gray-500 pl-4 italic mb-4">
                "Great article, [Name]! I found the section on responsive design especially useful. Have you noticed any emerging trends in CSS frameworks that could complement this?"
            </blockquote>
            <h3 className="text-xl font-semibold mb-2">Send Direct Messages:</h3>
            <p className="mb-4">Be polite, respectful, and express genuine curiosity. Ask for advice or insights in a way that shows you're eager to learn and grow, rather than asking for favors.</p>
            <p className="mb-2">Example message:</p>
            <blockquote className="border-l-4 border-gray-500 pl-4 italic mb-4">
                "Hi [Name], I'm new to front-end development and really admire your work in this field. I'm curious—what advice would you give someone looking to grow and find their first opportunities?"
            </blockquote>
            <p className="mb-4">Remember, it's all about building relationships, not just asking for favors! Be authentic, show appreciation for their time, and follow up occasionally to nurture the connection.</p>
            <img className='my-6 lg:w-[40%] object-contain' src="https://res.cloudinary.com/belong/image/upload/v1729578517/fullstack-bootcamp/what-next/strika_a_conversation_idzgmv.jpg" alt="Conversation" />
        <h3 className="text-xl font-semibold mb-2">Resources:</h3>
            <p className="mb-2">How to initiate a conversation</p>
            <iframe
                className='md:w-[560px] md:h-[315px] w-[100%]'
                src="https://www.youtube.com/embed/6JG3JDc8ldg?si=zXKcpQu2n9YXp-fK"         
                                      title='How to start Conversation'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen>

                </iframe>
               
               <p className='mt-4'>
                <a href="https://www.linkedin.com/business/marketing/blog/measurement/top-tips-for-measuring-and-improving-engagement-on-linkedin#:~:text=Publish%20Frequently,than%20those%20that%20don%27t." className="text-blue-600 hover:underline" rel="noreferrer noopener" target="_blank">
                Top Tips for Measuring and Improving Engagement on LinkedIn
                </a>
                </p>
               
       </div>
    );
};

export default LinkedinConversation;