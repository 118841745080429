import React from 'react';
import LinkedinAccount from './linkedin/LinkedinAccount';
import LinkedinProfile from './linkedin/LinkedinProfile';
import LinkedinProject from './linkedin/LinkedinProject';
import LinkedinNetwork from './linkedin/LinkedinNetwork';
import LinkedinConversation from './linkedin/LinkedinConversation';
import LinkedinPost from './linkedin/LinkedinPost';
import ColdEmailWhat from './cold-email/ColdEmailWhat';
import ColdEmailStructure from './cold-email/ColdEmailStructure';
import ColdEmailSample from './cold-email/ColdEmailSample';
import ColdEmailContact from './cold-email/ColdEmailContact';
import ColdEmailNoResponse from './cold-email/ColdEmailNoResponse';
import PortfolioVisibility from './portfolio/PortfolioVisibility';
import PortfolioPublishing from './portfolio/PortfolioPublishing';
import PortfolioBranding from './portfolio/PortfolioBranding';
import GrowthMindset from './growth/GrowthMindset';
import WhatCanYouDo from './growth/WhatCanYouDo';
import ActionableQuestion from './growth/ActionableQuestion';

const WayForward = ({  subHeading }) => {
  
  return (
    <div>
      {subHeading === 'Making an Account on LinkedIn' && <LinkedinAccount />}
      {subHeading === 'Updating Your Profile' && <LinkedinProfile />}
      {subHeading === 'Adding Your Project on LinkedIn' && <LinkedinProject />}
      {subHeading === 'How to Network?' && <LinkedinNetwork />}
      {subHeading === 'How to Strike Conversations on LinkedIn?' && <LinkedinConversation />}
      {subHeading === 'Post, Share and Reply on LinkedIn' && <LinkedinPost />}
      {subHeading === 'What and Why of Cold Emailing' && <ColdEmailWhat />}
      {subHeading === 'Structure of Cold Emails' && <ColdEmailStructure />}
      {subHeading === 'Sample Cold Emails for Outreach to HRs and Industry Contacts' && <ColdEmailSample/>}
      {subHeading === 'Who to Write To and How to Find Contacts' && <ColdEmailContact/>}
      {subHeading === 'What to Do If You Get No Response?' && <ColdEmailNoResponse/>}
      {subHeading === 'Why Expand Your Portfolio Visibility?' && <PortfolioVisibility/>}
      {subHeading === 'Publishing Your Work on Developer & Creative Platforms' && <PortfolioPublishing/>}
      {subHeading === 'Personal Branding: Enhancing Your Brand with a Custom Domain' && <PortfolioBranding/>}
      {subHeading === 'What Should Be Your Mindset?' && <GrowthMindset/>}
      {subHeading === 'What Can You Do?' && <WhatCanYouDo/>}
      {subHeading === 'Actionable Questions for Reflection' && <ActionableQuestion/>}
    </div>
  );
};

export default WayForward;
