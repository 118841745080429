import React, { useEffect } from 'react';

const ColdEmailSample = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Sample Cold Emails for Outreach to HRs and Industry Contacts</h2>
            
            <h3 className="text-xl font-semibold mb-2">To HR:</h3>
            <div className="bg-gray-100 p-4 rounded-md mb-6">
                <p className="font-semibold">Subject: Front-End Developer Seeking Internship Opportunities</p>
                <p className="mt-2">Hi [HR Name],</p>
                <p className="mt-2">I hope this message finds you well. My name is [Your Name], and I recently completed a Full Stack Bootcamp where I built projects using HTML, CSS, Bootstrap, and JavaScript. I'm actively seeking internship opportunities in front-end development and would love to explore any openings at [Company]. You can view my portfolio here: [Website Link].</p>
                <p className="mt-2">Looking forward to hearing from you.</p>
                <p className="mt-2">Best regards,<br/>[Your Name]</p>
            </div>
            
            <h3 className="text-xl font-semibold mb-2">To Industry Contact:</h3>
            <div className="bg-gray-100 p-4 rounded-md mb-6">
                <p className="font-semibold">Subject: Connecting with a Fellow Developer</p>
                <p className="mt-2">Hi [Contact Name],</p>
                <p className="mt-2">I came across your profile on LinkedIn and was inspired by your work in front-end development. I'm [Your Name], a recent bootcamp graduate, and I've been working on projects using HTML, CSS, and JavaScript. I'd love to connect and hear your thoughts on breaking into the field. Feel free to check out my portfolio here: [Website Link].</p>
                <p className="mt-2">Thanks for your time!</p>
                <p className="mt-2">Best,<br/>[Your Name]</p>
            </div>
            
            <p className="mb-4">Remember to personalize these templates with your own information and tailor them to the specific person or company you're reaching out to. Always keep your emails professional, concise, and focused on building a genuine connection.</p>
            <img className='my-6 lg:w-[60%]' src="https://res.cloudinary.com/belong/image/upload/v1729578516/fullstack-bootcamp/what-next/sample_cold_email_cf57cr.png" alt="what-cold-email" />
            <h3 className="text-xl font-semibold mb-2">Resources:</h3>
            <p className='mt-4'>
                     <a href="https://www.zendesk.com/in/blog/cold-email-templates/" className="text-blue-600 hover:underline" rel="noreferrer noopener" target="_blank">7 cold email templates that skyrocket response rates | Zendesk India </a>       
                </p>
                <p className='mt-4'>
                     <a href="https://satakshigarg.medium.com/cold-email-message-template-i-use-ac7effa4556e" className="text-blue-600 hover:underline" rel="noreferrer noopener" target="_blank">Cold Email/Message Template I use.. | by Satakshi Garg </a>       
                </p>
        </div>
    );
};

export default ColdEmailSample;