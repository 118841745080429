import React, { useEffect } from 'react';

const LinkedinProject = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
 
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Adding Your Project on LinkedIn</h2>
            <p className="mb-4">Showcase your bootcamp project or other completed projects to let connections see what you've built:</p>
            <ul className="list-disc pl-6 mb-4">
                <li><strong>Projects Section:</strong> Add your project here, providing more context and technical detail about what you built. This is where you can expand on the technologies used and the challenges you overcame.</li>
                <li><strong>Link Your Website:</strong> Include a link to your live personal website and GitHub repository so people can check out your work.</li>
            </ul>
            <p className="mb-4">Example:</p>
            <blockquote className="border-l-4 border-gray-500 pl-4 italic mb-4">
                "Built a fully responsive personal portfolio website using HTML, CSS, and JavaScript. The website showcases my skills in front-end development, including responsive design and dynamic content integration. View my portfolio here: [Link to website]."
            </blockquote>
            <h3 className="text-xl font-semibold mb-2">Showcasing Projects on LinkedIn</h3>
            <iframe
                className='md:w-[560px] md:h-[315px] w-[100%] mb-4'
                src="https://www.youtube.com/embed/vVKdIHeOtKg?si=XNU5cynqqKMDwVRA"                 title='Showcasing Projects on LinkedIn'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen></iframe>
            
        </div>
    );
};

export default LinkedinProject;