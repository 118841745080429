import React, { useEffect } from 'react';

const GrowthMindset = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">What Should Be Your Mindset?</h2>
            
            <p className="mb-4">Continuous growth mindset is the secret to long-term success in any field. Keep the curiosity alive, keep building, keep learning, and keep improving your skills. By sharing your journey, expanding your portfolio with new projects, seeking feedback, and maintaining a growth mindset, you'll position yourself as a strong candidate for internships and beyond. Remember, this is just the beginning of your development journey—there's always room to grow!</p>
            
            <h3 className="text-xl font-semibold mb-2">Are You Seeing This as More Than a Certificate?</h3>
            <p className="mb-4">Your bootcamp certificate is a great achievement, but the real value lies in how you use what you've learned. Instead of viewing it as just a credential, ask yourself:</p>
            <ul className="list-disc pl-6 mb-4">
                <li>What skills did I truly master during the bootcamp?</li>
                <li>How can I use my project to showcase those skills?</li>
                <li>What skills can I improve on?</li>
            </ul>
            <p className="mb-4"><strong>Next Step:</strong> Go back to your capstone project and make sure it reflects the best of your abilities. Add a strong project description in your portfolio to highlight the challenges you solved, the tools you used (like HTML, CSS, JavaScript), and how it demonstrates your creativity.</p>

            <h3 className="text-xl font-semibold mb-2">How Are You Leveraging This Experience?</h3>
            <p className="mb-4">Think about how the bootcamp has helped shape you as a developer and how it adds value to your portfolio. Ask yourself:</p>
            <ul className="list-disc pl-6 mb-4">
                <li>Which specific skills (HTML, CSS, Bootstrap, Git, JavaScript) should I emphasize when applying for internships?</li>
                <li>What unique aspect of my project or experience can I highlight to stand out?</li>
            </ul>
            <p className="mb-4"><strong>Next Step:</strong> Update your LinkedIn, personal website, and GitHub repository with clear bullet points about your technical skills and the specific projects you worked on. Use these points in your cold emails or during interviews to demonstrate your hands-on experience.</p>

            <h3 className="text-xl font-semibold mb-2">Are You Focusing on Growth?</h3>
            <p className="mb-4">Growth is not just about completing tasks, but about building your skills over time. Ask yourself:</p>
            <ul className="list-disc pl-6 mb-4">
                <li>What's the next challenge I can take on to deepen my knowledge?</li>
                <li>Am I keeping up with industry trends and learning new technologies that are relevant to front-end development?</li>
            </ul>
            <p className="mb-4"><strong>Next Step:</strong> Set aside time each week to work on a new coding challenge, explore advanced JavaScript features, or contribute to an open-source project. Follow tech blogs or join communities like Stack Overflow to stay updated. Consistent learning will keep you competitive.</p>

            <h3 className="text-xl font-semibold mb-2">Resources:</h3>
            <p className="mb-2">Power of Growth Mindset - Growth Mindset Introduction: What it is, How it Works, and Why it Matters</p>
            <iframe
                className="w-full md:w-[560px] h-[315px] mb-4"
                src="https://www.youtube.com/embed/75GFzikmRY0?si=TMMfA2vbJWt1iKV-" 
                title="Power of Growth Mindset"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
            <p className="mb-2">Forget employability, it is all about mindset | Charlie Reeve | TEDxAstonUniversity</p>
            <iframe
                className="w-full md:w-[560px] h-[315px] mb-4"
                src="https://www.youtube.com/embed/6mw9YRNj0I4?si=qLjw21iSODlU8GWp"
                title="Forget employability, it is all about mindset"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default GrowthMindset;