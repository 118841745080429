const html_css_questions = [
  {
    question:
      "You are responsible for designing a comprehensive registration form that collects a user's first name, last name, email, and password. Which combination of HTML elements should you use to ensure that each input field is appropriately labeled and the form is submitted seamlessly?",
    options: [
      '<div>, <h1>, <span>',
      '<form>, <input>, <label>, <select>, <button>',
      '<section>, <footer>, <nav>, <aside>',
      '<table>, <tr>, <td>, <th>',
    ],
    correct_answer_idx: 1,
  },
  {
    question:
      'You are asked to apply specific styles to a paragraph element on your webpage, including making the text color red and setting the font size to 20px. Which CSS rule would correctly achieve this?',
    options: [
      'p { color: red; font-size: 20px; }',
      'text-color: red; size: 20px;',
      'p { color: 20px; }',
      'p { background: red; font-size: 20px; }',
    ],
    correct_answer_idx: 0,
  },
  {
    question:
      'You need to eliminate the default margin from all <h1> elements across your webpage. Which CSS rule would you apply to remove this margin?',
    options: [
      'h1 { padding: 0; }',
      'h1 { margin: none; }',
      'h1 { margin: 0; }',
      'h1 { margin: auto; }',
    ],
    correct_answer_idx: 2,
  },
  {
    question:
      'When creating a form that includes a checkbox option for users, which HTML element should you use to define a checkbox input field?',
    options: [
      "<input type='checkbox'>",
      '<check>',
      '<checkbox>',
      "<input type='check'>",
    ],
    correct_answer_idx: 0,
  },
  {
    question:
      'As part of styling a button on your webpage, you want it to have a green background with white text. Which CSS rule correctly applies a green background to a button and sets the text color to white?',
    options: [
      'background; color',
      'background-color; color',
      'background; text-color',
      'background-color; text-color',
    ],
    correct_answer_idx: 1,
  },
  {
    question:
      'To center a div element both horizontally and vertically, you decide to use Flexbox. Complete the missing CSS code to achieve this: .container { display: ___; justify-content: ___; align-items: ___; height: 100vh; }',
    options: [
      'flex; space-between; top',
      'block; center; middle',
      'flex; start; stretch',
      'flex; center; center',
    ],
    correct_answer_idx: 3,
  },
];

const bootstrap_js_questions = [
  {
    question:
      'You want to toggle the visibility of a modal in Bootstrap using JavaScript. Which code snippet should you use?',
    options: [
      "$('#myModal').toggle();",
      "$('#myModal').modal('show');",
      "$('.modal').hide();",
      "document.getElementById('myModal').show();",
    ],
    correct_answer_idx: 1,
  },
  {
    question:
      'To make a Bootstrap card component with a header and body, which structure is appropriate?',
    options: [
      `<div class="card">
        <div class="card-header">Header</div>
        <div class="card-body">Body content</div>
      </div>`,
      `<div class="card">
        <h5 class="card-title">Title</h5>
        <div class="card-content">Content</div>
      </div>`,
      `<div class="card">
        <header class="card-header">Header</header>
        <section class="card-body">Body content</section>
      </div>`,
      `<div class="card">
        <div class="title">Title</div>
        <div class="content">Content</div>
      </div>`,
    ],
    correct_answer_idx: 0,
  },
  {
    question:
      'You need to create a dropdown menu in Bootstrap. Which of the following structures is correct?',
    options: [
      `<div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">Dropdown</button>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
        </div>
      </div>`,
      `<ul class="dropdown">
        <li class="dropdown-toggle">Dropdown</li>
        <ul class="dropdown-menu">
          <li><a href="#">Action</a></li>
          <li><a href="#">Another action</a></li>
        </ul>
      </ul>`,
      `<div class="dropdown">
        <button class="btn dropdown-toggle">Dropdown</button>
        <div class="dropdown-content">
          <a href="#">Action</a>
          <a href="#">Another action</a>
        </div>
      </div>`,
      `<div class="dropdown">
        <button class="btn btn-primary">Dropdown</button>
        <div class="dropdown-list">
          <a href="#">Action</a>
          <a href="#">Another action</a>
        </div>
      </div>`,
    ],
    correct_answer_idx: 0,
  },
  {
    question:
      'You need to create an event handler in JavaScript that displays an alert when a button is clicked. Which code snippet accomplishes this?',
    options: [
      `document.getElementById('myButton').onclick = function() {
        alert('Button clicked!');
      };`,
      `document.querySelector('#myButton').addEvent('click', function() {
        alert('Button clicked!');
      });`,
      `document.getElementById('myButton').addEventListener('click', function() {
        alert('Button clicked!');
      });`,
      `document.querySelector('button').click(function() {
        alert('Button clicked!');
      });`,
    ],
    correct_answer_idx: 2,
  },
  {
    question:
      'To attach a click event to a button that changes the text of a paragraph when clicked, which JavaScript code would you use?',
    options: [
      `document.querySelector('#changeTextButton').onclick = function() {
        document.querySelector('#myParagraph').innerText = 'Text changed!';
      };`,
      `document.getElementById('changeTextButton').setAttribute('onclick', function() {
        document.getElementById('myParagraph').innerText = 'Text changed!';
      });`,
      `document.getElementById('changeTextButton').addEventListener('click', function() {
        document.getElementById('myParagraph').innerText = 'Text changed!';
      });`,
      `document.write('changeTextButton').onclick = function() {
        document.write('Text changed!');
      };`,
    ],
    correct_answer_idx: 2,
  },
  {
    question:
      'To create a responsive card layout with Bootstrap that stacks vertically on small screens but has three cards in a row on larger screens, complete the missing class names:',
    options: ['4; 4; 4', '6; 6; 6', '12; 12; 12', '3; 3; 3'],
    correct_answer_idx: 0,
  },
];

const hosting_github_questions = [
  {
    question:
      'Your project is hosted on GitHub, and you want to set up Continuous Integration (CI) to automatically run tests and deploy your code when changes are made to the repository. Which tool, commonly used with GitHub Pages, provides a built-in solution for setting up CI/CD pipelines, and how does it work?',
    options: ['Jenkins', 'GitHub Actions', 'Docker', 'Travis CI'],
    correct_answer_idx: 1,
  },
  {
    question:
      "After making several changes to files in your project, you want to prepare them for a commit but aren't ready to save them in the repository yet. You need to stage the changes. What command would you use, and what does it accomplish in the context of Git's workflow?",
    options: ['git add', 'git commit', 'git push', 'git save'],
    correct_answer_idx: 0,
  },
  {
    question:
      'You need to review the entire history of changes and previous commits in your Git repository to understand the progression of your project. Which command will display the detailed commit history, including commit hashes, messages, and timestamps?',
    options: ['git status', 'git commit -m', 'git log', 'git history'],
    correct_answer_idx: 2,
  },
  {
    question:
      'Your project is hosted on the GitHub, and you want to set up Continuous Integration (CI) to automatically run tests and deploy your code when changes are made to the repository. Which tool, commonly used with GitHub Pages, provides a built-in solution for setting up CI/CD pipelines, and how does it work?',
    options: ['Jenkins', 'GitHub Actions', 'Docker', 'Travis CI'],
    correct_answer_idx: 1,
  },
  {
    question:
      'After making updates to your local project, what is the correct sequence of Git commands to push these changes to GitHub?',
    options: [
      'git commit -m "message", git push origin main, git add .',
      'git add ., git commit -m "message", git push origin main',
      'git push origin main, git add ., git commit -m "message"',
      'git add ., git push origin main, git commit -m "message"',
    ],
    correct_answer_idx: 1,
  },
  {
    question:
      'To set up a project for hosting on GitHub Pages, which of the following steps is necessary?',
    options: [
      'Create a branch named gh-pages, push your code there, and configure GitHub Pages to use that branch.',
      'Push to the main branch and enable GitHub Pages in repository settings.',
      'Rename the main branch to gh-pages to automatically publish it.',
      'Create a new branch for deployment and merge it with the main branch before enabling GitHub Pages.',
    ],
    correct_answer_idx: 0,
  },
];

const reflective_activity_questions = [
  {
    question:
      'How comfortable are you with creating the structure of a webpage using HTML and CSS?',
    hint: 'Consider how well you can use HTML tags to build the page and style it with CSS properties.',
  },
  {
    question:
      'How confident are you in using Bootstrap to create responsive layouts and JavaScript to add interactivity?',
    hint: 'Reflect on your ability to use Bootstrap’s grid system and JavaScript for dynamic features like buttons or forms.',
  },
  {
    question:
      'How comfortable are you with using Git and GitHub for version control?',
    hint: 'Think about how well you can make commits, push code to a repository, and manage branches.',
  },
  {
    question:
      'Does your portfolio website effectively showcase the skills you’ve learned during the bootcamp?',
    hint: 'Reflect on whether your portfolio highlights your strengths and areas where you could improve.',
  },
  {
    question:
      'How confident are you in troubleshooting coding issues and debugging errors?',
    hint: 'Think about how well you can independently identify and resolve problems in your code.',
  },
  {
    question:
      'Do you feel ready to use your front-end development skills in real-world projects?',
    hint: 'Consider how prepared you are to handle tasks like building or modifying websites for clients or employers.',
  },
];

// const foo = {
//   percentage: 0,
//   correct_answer_count: 0,
//   total_question_count: 0,
//   response: {
//     js_css_questions: [
//       {
//         question:
//           'You want to toggle the visibility of a modal in Bootstrap using JavaScript. Which code snippet should you use?',
//         options: [
//           "$('#myModal').toggle();",
//           "$('#myModal').modal('show');",
//           "$('.modal').hide();",
//           "document.getElementById('myModal').show();",
//         ],
//         correct_answer_idx: 1,
//       },
//     ],
//   },
// };

// const reflective_analysis = [
//   {
//     question: '',
//     hint: '',
//     user_response: '',
//   },
// ];

export {
  html_css_questions,
  bootstrap_js_questions,
  hosting_github_questions,
  reflective_activity_questions,
};
