import React, { useEffect } from 'react';

const ColdEmailStructure = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Structure of Cold Emails</h2>
            
            <p className="mb-4">A good cold email is like a short, well-crafted message that grabs attention and highlights why you're a good fit for the company. Here are some key guidelines to keep in mind:</p>
            
            <ul className="list-disc pl-6 mb-4">
                <li><strong>Short and to the point:</strong> No one has time to read long emails.</li>
                <li><strong>Personalized:</strong> Mention something specific about the person you're writing to, like their role or the company they work for.</li>
                <li><strong>Professional:</strong> Be polite, clear, and friendly.</li>
                <li><strong>Include Links:</strong> Always share your website and GitHub to make it easy for them to see your work.</li>
            </ul>
            
            <h3 className="text-xl font-semibold mb-2">Email Structure</h3>
            
            <h4 className="text-lg font-semibold mb-2">1. Introduction and Purpose</h4>
            <p className="mb-4">Start with a friendly introduction and explain why you're reaching out. Be clear, concise, and mention what draws you to the company. For example:</p>
            <blockquote className="border-l-4 border-gray-500 pl-4 italic mb-4">
                "Hi [Name], I'm [Your Name], a front-end developer passionate about building responsive websites. I'm reaching out because I admire [Company]'s work in [specific area], and I'd love to explore potential opportunities."
            </blockquote>
            
            <h4 className="text-lg font-semibold mb-2">2. Why You're a Good Fit</h4>
            <p className="mb-4">Briefly explain why your skills align with the company's work. Highlight your relevant experience and what you like about the company's mission, projects, or products. For example:</p>
            <blockquote className="border-l-4 border-gray-500 pl-4 italic mb-4">
                "With my experience in HTML, CSS, and JavaScript, and a passion for creating user-friendly web interfaces, I believe I can contribute to [Company]'s ongoing projects in [specific area]."
            </blockquote>
            
            <h4 className="text-lg font-semibold mb-2">3. Brief Project/Work Description with Website Link</h4>
            <p className="mb-4">Mention your portfolio and provide a brief overview of a recent project. For example:</p>
            <blockquote className="border-l-4 border-gray-500 pl-4 italic mb-4">
                "I recently built a personal portfolio website that showcases my skills in front-end development. You can check it out here: [Website Link]."
            </blockquote>
            
            <h4 className="text-lg font-semibold mb-2">4. Call to Action</h4>
            <p className="mb-4">End your email with a polite ask, such as connecting for a chat or exploring internship opportunities. For example:</p>
            <blockquote className="border-l-4 border-gray-500 pl-4 italic mb-4">
                "I'd love to connect and learn more about potential opportunities at [Company]. Would it be possible to schedule a short call?"
            </blockquote>
            
            <h3 className="text-xl font-semibold mb-2">Resources:</h3>
            <div >
            <p className="my-2">How to Write a Cold Email </p>

                <iframe
                className='md:w-[560px] md:h-[315px] w-[100%]'
                src="https://www.youtube.com/embed/2VG1Px105Vc?si=UMAxvY73ybQbnImd"         
                                      title='How to Create a LinkedIn Account'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen>

                </iframe>
                <p className="mt-6 mb-2">Tricks to write cold email</p>

                <iframe
                className='md:w-[560px] md:h-[315px] w-[100%]'
                src="https://www.youtube.com/embed/YtiLBfDfadY?si=AtO8J6tG7AZyYnlt"     
                                      title='How to Create a LinkedIn Account'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen>

                </iframe>
                </div>    
                  </div>
    );
};

export default ColdEmailStructure;