import React, { useEffect } from 'react';

const LinkedinNetwork = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
 
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">How to Network?</h2>
            <p className="mb-4">Networking is key to landing internships and growing your career. Here's how to get started:</p>
            
            <h3 className="text-xl font-semibold mb-2">Identify Relevant Connections:</h3>
            <p className="mb-4">Start by searching for front-end developers, recruiters, or tech professionals working at companies you're interested in. Look for people who share your field of interest or who could offer valuable insights into the industry.</p>
            
            <h3 className="text-xl font-semibold mb-2">Send a Personalized Connection Request:</h3>
            <p className="mb-4">Don't just hit "Connect"! Add a message to make your request more personal. Show genuine interest in the person's work and why you'd like to connect. Here's an example message you can use:</p>
            
            <blockquote className="border-l-4 border-gray-500 pl-4 italic mb-4">
                "Hi [Name], I'm [Your Name], a front-end developer who recently completed a bootcamp. I've been following your work at [Company], and I really admire your approach to [specific aspect of their work]. I'd love to connect and learn more about the field and your experiences."
            </blockquote>
            
            <p className="mb-4">This approach shows that you're thoughtful, interested in their work, and eager to learn, increasing the chances of building a meaningful connection.</p>
        <img className='my-6 lg:w-[60%]' src="https://res.cloudinary.com/belong/image/upload/v1729578516/fullstack-bootcamp/what-next/Networking_b5bqw6.jpg" alt="Networking" />
        <h3 className="text-xl font-semibold mb-2">Resources:</h3>
            <p className="mb-2">Building a Strong Network</p>
            <iframe
                className='md:w-[560px] md:h-[315px] w-[100%]'
                src="https://www.youtube.com/embed/Q41b3OBcE0c?si=OGLD2JFynSfvGKYg"          
                                      title='How to Create a LinkedIn Account'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen>

                </iframe>
               
               <p className='mt-4'><a href="https://expandi.io/blog/how-to-network-on-linkedin/" className="text-blue-600 hover:underline" rel="noreferrer noopener" target="_blank">How to network on LinkedIn-10 Do’s and Don’ts for You to Consider</a></p></div>
    );
};

export default LinkedinNetwork;